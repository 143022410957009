import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/kundenportal/felder',
    name: 'cp.arable-fields.index',
    component: () => import('./pages/ArableFieldsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/auftraege',
    name: 'cp.orders.index',
    component: () => import('./pages/OrdersIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/auftraege/erfassen',
    name: 'cp.orders.create',
    component: () => import('./pages/OrdersCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/auftraege/:id/bearbeiten',
    name: 'cp.orders.edit',
    component: () => import('./pages/OrdersEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/auftraege/:id/karte',
    alias: '/orders/:id/map',
    name: 'cp.orders.map',
    component: () => import('./pages/OrdersMap.vue'),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/kundenportal/auftraege/:id',
    name: 'cp.orders.show',
    component: () => import('./pages/OrdersShow.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/wochenrapport',
    redirect: '/kundenportal/wochenrapporte',
  },
  {
    path: '/kundenportal/wochenrapporte',
    name: 'cp.rapports.index',
    component: () => import('./pages/RapportsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/wochenrapporte/:id',
    name: 'cp.rapports.show',
    component: () => import('./pages/RapportsShow.vue'),
    meta: { auth: true },
    props: true,
  },

  {
    path: '/orders/create',
    name: 'orders.create',
    component: () => import('./pages/OrdersCreateAdmin.vue'),
    meta: { auth: true },
  },
  {
    path: '/orders',
    name: 'orders.index',
    component: () => import('./pages/OrdersFieldsClusterIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/orders/:id',
    name: 'orders.edit',
    component: () => import('./pages/OrdersEditAdmin.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/faqs',
    name: 'cp.faqs.index',
    component: () => import('./pages/FaqView.vue'),
    meta: { auth: true },
  },
  {
    path: '/manage-faq',
    name: 'faqs.index',
    component: () => import('./pages/FaqManage.vue'),
    meta: { auth: true },
  },
  {
    path: '/rapport/:id',
    name: 'rapports.show',
    component: () => import('./pages/RapportsShow.vue'),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/rapport',
    name: 'rapports.index',
    component: () => import('./pages/RapportsOverview.vue'),
    meta: { auth: true },
  },
  {
    path: '/rapport/week/:week',
    name: 'rapports.week',
    component: () => import('./pages/RapportsWeek.vue'),
    meta: { auth: true },
  },
  {
    path: '/felder/:id/karte',
    name: 'arable-fields.show.map',
    component: () => import('./pages/ArableFieldsMap.vue'),
    meta: { auth: 'optional' },
    props: true,
  },
]
