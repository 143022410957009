import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from './store/auth'
import Posthog from 'posthog-js'
import { eventBus } from '@/utils/eventBus'

import { routes as authRoutes } from './features/auth/router'
import { routes as employeeInterviewRoutes } from './features/employee-interviews/router'
import { routes as formRoutes } from './features/forms/router'
import { routes as orderRoutes } from './features/order-management/router'
import { routes as profileRoutes } from './features/profile/router'
import { routes as resourcePlannerRoutes } from './features/resource-planner/router'
import { routes as roomdispositionerRoutes } from './features/roomdispositioner/router'
import { routes as settingsRoutes } from './features/settings/router'
import { routes as sharedRoutes } from './features/shared/router'
import { routes as timeRecordingRoutes } from './features/time-recording/router'
import { routes as transactionRoutes } from './features/transactions/router'
import { routes as userFeatureRoutes } from './features/users/router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...authRoutes,

    {
      path: '/',
      component: () => import('./Layouts/PageLayout.vue'),
      children: [
        ...employeeInterviewRoutes,
        ...formRoutes,
        ...transactionRoutes,
        ...userFeatureRoutes,
        ...settingsRoutes,
        ...resourcePlannerRoutes,
        ...roomdispositionerRoutes,
        ...orderRoutes,
        ...timeRecordingRoutes,
        ...profileRoutes,
        ...sharedRoutes,
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (from.name === to.name) return {}

    if (!savedPosition) {
      return { top: 0, left: 0 }
    }

    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        eventBus.off('dataLoaded', returnScroll)
        returnScroll()
      }, 500)

      function returnScroll() {
        clearTimeout(timeout)
        resolve(savedPosition || { top: 0, left: 0 })
      }

      eventBus.once('dataLoaded', returnScroll)
    })
  },
})

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore()
  const authUser = authStore.user

  const reqAuth = to.meta.auth
  const loginQuery = { path: '/login', query: { redirect: to.fullPath } }

  if (reqAuth) {
    if (!authUser) {
      await authStore.fetchUser()
    }

    if (reqAuth === 'optional') {
      return next()
    }

    if (!authStore.user) {
      return next(loginQuery)
    }

    if (
      !authStore.user.is_password_changed &&
      to.path !== '/profile/change-password'
    ) {
      return next('/profile/change-password')
    }
  }

  next() // make sure to always call next()!
})

router.afterEach((to, _from, failure) => {
  if (!failure) {
    nextTick(() => {
      Posthog.capture('$pageview', {
        path: to.fullPath,
      })
    })
  }
})

router.onError((error, to) => {
  const chunkFailedMessage = 'Failed to fetch dynamically imported module'
  const cssLoadFailedMessage = 'Unable to preload CSS for'
  if (
    (error.message.includes(chunkFailedMessage) ||
      error.message.includes(cssLoadFailedMessage)) &&
    import.meta.env.PROD
  ) {
    window.open(to.fullPath, '_self')
  }
})

export default router
