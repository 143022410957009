import { t } from '@/plugins/i18n'
import { UserType, WarningType } from '../utils'
import { ProjectState } from '../constants'

const fieldOptions = {
  responsibilities: () => [
    {
      value: 'driver',
      text: t('forms.season-application.responsibilities.driver'),
    },
    {
      value: 'group-leader',
      text: t('forms.season-application.responsibilities.group-leader'),
    },
    {
      value: 'house-responsibility',
      text: t('forms.season-application.responsibilities.house-responsibility'),
    },
    {
      value: 'material-responsibility',
      text: t(
        'forms.season-application.responsibilities.material-responsibility'
      ),
    },
    {
      value: 'vehicle-responsibilitry',
      text: t(
        'forms.season-application.responsibilities.vehicle-responsibilitry'
      ),
    },
    {
      value: 'translator',
      text: t('forms.season-application.responsibilities.translator'),
    },
  ],

  livingPlaces: () =>
    [
      {
        value: 'on-my-own',
        text: t('forms.season-application.living-places.on-my-own'),
      },
      {
        value: 'doesnt-matter',
        text: t('forms.season-application.living-places.doesnt-matter'),
      },
    ].map((place) => {
      if (typeof place === 'string') {
        return {
          value: place,
          text: place,
        }
      }

      return place
    }),

  formSubmissionStates: () => [
    {
      value: 'pending',
      text: t('forms.states.pending'),
    },
    {
      value: 'accepted',
      text: t('forms.states.accepted'),
    },
    {
      value: 'rejected',
      text: t('forms.states.rejected'),
    },
  ],

  genders: () => [
    {
      value: 'man',
      text: t('general.genders.man'),
    },
    {
      value: 'woman',
      text: t('general.genders.woman'),
    },
  ],

  civilStates: () => [
    {
      value: 'single',
      text: t('general.civil-states.single'),
    },
    // {
    //   value: 'registered-partnership',
    //   text: t('general.civil-states.registered-partnership'),
    // },
    {
      value: 'married',
      text: t('general.civil-states.married'),
    },
    {
      value: 'separated-but-married',
      text: t('general.civil-states.separated-but-married'),
    },
    {
      value: 'divorced',
      text: t('general.civil-states.divorced'),
    },
    {
      value: 'widowed',
      text: t('general.civil-states.widowed'),
    },
  ],

  languages: () => [
    {
      value: 'de',
      text: t('general.languages.de'),
    },
    {
      value: 'en',
      text: t('general.languages.en'),
    },
    {
      value: 'ro',
      text: t('general.languages.ro'),
    },

    {
      value: 'fr',
      text: t('general.languages.fr'),
    },
  ],

  yesOrNo: () => [
    {
      value: true,
      text: t('general.form-fields.yes'),
    },
    {
      value: false,
      text: t('general.form-fields.no'),
    },
  ],

  workLocations: () => [
    {
      value: 'switzerland',
      text: t('general.work-locations.switzerland'),
    },
    {
      value: 'homecountry',
      text: t('general.work-locations.homecountry'),
    },
  ],

  activeStates: () => [
    {
      value: null,
      text: t('forms.active-states.all'),
    },
    {
      value: true,
      text: t('forms.active-states.active'),
    },
    {
      value: false,
      text: t('forms.active-states.inactive'),
    },
  ],

  employeeTypes: () => [
    {
      value: null,
      text: t('forms.employee-types.all'),
    },
    {
      value: UserType.Employee,
      text: t('forms.employee-types.employee'),
    },
    {
      value: UserType.Worker,
      text: t('forms.employee-types.worker'),
    },
  ],

  labourTypes: () => [
    {
      value: 'productive_hours',
      text: 'Produktivstunden',
    },
    {
      value: 'non_chargeable_hours',
      text: 'Nicht verrechenbare Stunden',
    },
    {
      value: 'holidays',
      text: 'Ferien',
    },
    {
      value: 'free',
      text: 'Frei',
    },
    {
      value: 'paid_absence',
      text: 'Bezahlte Abwesenheit',
    },
    {
      value: 'unpaid_absence',
      text: 'Unbezahlte Abwesenheit',
    },
  ],

  warningTypes: () => [
    {
      value: WarningType.Verbal,
      text: t('users.warnings.warning-types.verbal'),
    },
    {
      value: WarningType.Written,
      text: t('users.warnings.warning-types.written'),
    },
  ],

  projectStates: () => [
    {
      value: ProjectState.Pending,
      text: 'Aussenstehend',
    },
    {
      value: ProjectState.Accepted,
      text: 'Akzeptiert',
    },
    {
      value: ProjectState.Rejected,
      text: 'Abgelehnt',
    },
  ],
}

export const {
  responsibilities,
  livingPlaces,
  formSubmissionStates,
  genders,
  civilStates,
  languages,
  yesOrNo,
  activeStates,
  employeeTypes,
  labourTypes,
  warningTypes,
  projectStates,
  workLocations,
} = fieldOptions

export const useOptions = function (options) {
  if (Array.isArray(options)) {
    return options.reduce((result, option) => {
      result[option] = fieldOptions[option]()
      return result
    }, {})
  }

  return Object.keys(options).reduce((result, option) => {
    result[option] = fieldOptions[options[option]]()
    return result
  }, {})
}

export const mapOptions = function (options) {
  if (Array.isArray(options)) {
    return options.reduce((result, option) => {
      result[option] = { get: () => fieldOptions[option]() }
      return result
    }, {})
  }

  return Object.keys(options).reduce((result, option) => {
    result[option] = { get: () => fieldOptions[options[option]]() }
    return result
  }, {})
}
