import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/roomdispositioner/reservationen',
    name: 'Roomdispositioner',
    component: () => import('./pages/RoomdispositionerDashboard.vue'),
    meta: { auth: true },
  },
  {
    path: '/roomdispositioner/room-overview',
    name: 'RoomOverview',
    component: () => import('./pages/RoomOverview.vue'),
    meta: { auth: true },
  },
  {
    path: '/roomdispositioner/show-room',
    name: 'RoomOccupancy',
    component: () => import('./pages/RoomOccupancy.vue'),
    meta: { auth: true },
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: () => import('./pages/RoomsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/houses',
    name: 'houses.index',
    component: () => import('./pages/HousesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/houses/:id',
    name: 'houses.edit',
    component: () => import('./pages/HousesEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/houses/add',
    name: 'houses.create',
    component: () => import('./pages/HousesCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/rooms/add',
    name: 'AddRoom',
    component: () => import('./pages/RoomsCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/rooms/:id',
    name: 'ShowRoom',
    component: () => import('./pages/RoomsEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/inventars',
    name: 'Inventars',
    component: () => import('./pages/InventarsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/inventars/:id',
    name: 'ShowInventar',
    component: () => import('./pages/InventarsEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/beds',
    name: 'Beds',
    component: () => import('./pages/BedsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/beds/:id',
    name: 'ShowBed',
    component: () => import('./pages/BedsEdit.vue'),
    meta: { auth: true },
  },
]
