const listeners = {
  dataLoaded: new Set<(test: string) => void>(),
  showOrderPopup: new Set<(orderId: number) => void>(),

  employeeAddedToResource: new Set<
    (resourceId: number, employeeId: number) => void
  >(),
}

type ListenerTypes = {
  [K in keyof typeof listeners]: (typeof listeners)[K] extends Set<infer T>
    ? T
    : never
}

class EventBus {
  // Shorthand for registerBroadcastListener
  on<K extends keyof ListenerTypes>(
    channel: K,
    callback: ListenerTypes[K]
  ): () => void {
    return this.registerBroadcastListener(channel, callback)
  }

  // Shorthand for removeListener
  off<K extends keyof ListenerTypes>(
    channel: K,
    callback: ListenerTypes[K]
  ): void {
    this.removeListener(channel, callback)
  }

  once<K extends keyof ListenerTypes>(
    channel: K,
    callback: ListenerTypes[K]
  ): void {
    const removeListener = this.registerBroadcastListener(
      channel,
      (...args) => {
        callback(...args)
        removeListener()
      }
    )
  }

  registerBroadcastListener<K extends keyof ListenerTypes>(
    channel: K,
    callback: ListenerTypes[K]
  ): () => void {
    listeners[channel].add(callback as any)

    // Return a function to remove the listener
    return () => {
      listeners[channel].delete(callback as any)
    }
  }

  emit<K extends keyof ListenerTypes>(
    channel: K,
    ...args: Parameters<ListenerTypes[K]>
  ): void {
    listeners[channel]?.forEach((callback) => {
      // Spread the args to match the callback signature
      callback(...args)
    })
  }

  removeListener<K extends keyof ListenerTypes>(
    channel: K,
    callback: ListenerTypes[K]
  ): void {
    listeners[channel].delete(callback as any)
  }
}

export const eventBus = new EventBus()
