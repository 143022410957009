import moment from './moment'

export default {
  date(value) {
    return value ? moment(value).format('DD.MM.YYYY') : ''
  },
  week(date) {
    if (!date) return ''

    date = moment(date)
    return `${date.format('W (DD.MM.YYYY')} - ${date
      .clone()
      .add(6, 'days')
      .format('DD.MM.YYYY')})`
  },

  round(value, precision = 2) {
    return Math.round(value * 10 ** precision) / 10 ** precision
  },

  optionText(value, options) {
    const option = options.find((o) => o.value === value)
    return option ? option.text : value
  },

  optionsText(value, options) {
    if (!value || !Array.isArray(value)) return null
    return value
      .map((value) => {
        const option = options.find(
          (option) => option.value === value || option.id === value
        )

        if (!option) return value

        return option.text || option.name || option
      })
      .join(', ')
  },

  currency(value, currency = 'CHF') {
    return `${currency} ${value}.-`
  },

  SFr(value, currency = 'SFr') {
    return `${Number(value).toFixed(2)} ${currency}.`
  },
}
