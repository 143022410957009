import { resourceService } from '@/features/resource-planner/api/ResourceService'
import { confirmAction } from '@/utils'
import { t } from '@/plugins/i18n'
import { defineStore } from 'pinia'
import moment from 'moment'
import { useAuthStore } from '../../../store/auth'
import { useRoute, useRouter } from 'vue-router'
import { errorNotification } from '@/utils/notifications'
import axios from '@/plugins/axios'
import type { SweetAlertOptions } from 'sweetalert2'
import { handleError } from '@/utils/error-handling'
import debounce from 'lodash/debounce'
import {
  useSyncRouteParam,
  useSyncRouteParams,
} from '@/composables/useRouteParams'
import { createDatesArray, type Dateable } from '@/utils/dates'
import { useBreakPoints } from '@/composables/useBreakPoints'
import { useLocalStorage } from '@/composables/useLocalStorage'
import { sheduleQueryUpdate } from '@/utils/router-helper'

export function getCurrentDate() {
  const route = useRoute()
  if (route.query.date) {
    return moment(route.query.date as string).format('YYYY-MM-DD')
  }

  const checkTime = moment().hours(17).minutes(0).seconds(0)

  if (moment().isAfter(checkTime)) {
    return moment().add(1, 'days').format('YYYY-MM-DD')
  }
  return moment().format('YYYY-MM-DD')
}

export type ResourceCollectionByDay = {
  date: string
  resources: Domain.ResourcePlanner.DTO.ResourceData[]
  items: ResourceCollection[]
}

export type ResourceCollection = {
  id: number | string
  resources: Domain.ResourcePlanner.DTO.ResourceData[]
  name: string
  planner_entries: Domain.ResourcePlanner.DTO.PlannerEntryData[]
  customer: Domain.Users.DTO.CustomerData | null
}

export const useResourcePlannerStore = defineStore('resourcePlanner', () => {
  // state
  const breakpoints = useBreakPoints()

  const loadingDates = ref<string[]>([])
  const loadingResourceIds = ref<number[]>([])

  const date = ref(getCurrentDate())
  const router = useRouter()
  watch(date, () => {
    sheduleQueryUpdate({ date: date.value }, router)
  })

  function getDefaultPlannerMode(): Domain.Profile.Enums.ResourcePlannerMode {
    return useAuthStore().user?.settings?.resource_planner_mode || 'employees'
  }

  const filters = useSyncRouteParams(
    {
      editMode: getDefaultPlannerMode(),
      company_id: null,
    },
    {
      company_id: 'number',
    }
  )

  const showRobots = computed(
    () =>
      filters.value.editMode === 'robots' || filters.value.editMode === 'both'
  )
  const showEmployees = computed(
    () =>
      filters.value.editMode === 'employees' ||
      filters.value.editMode === 'both'
  )
  watch(filters, () => fetchAllResources(true), { deep: true })

  const additionalDays = useLocalStorage('resourcePlanner.additionalDays', {
    before: 0,
    after: 0,
  })

  const searchString = ref<string | null>(null)
  const dateRange = computed<[moment.Moment, moment.Moment]>(() => {
    const firstDate = moment(date.value).subtract(
      breakpoints.lg.value ? additionalDays.value.before || 0 : 0,
      'days'
    )
    const lastDate = moment(date.value).add(
      breakpoints.lg.value ? additionalDays.value.after || 0 : 0,
      'days'
    )

    return [firstDate, lastDate]
  })

  const resourcesSearchResult = ref<Domain.ResourcePlanner.DTO.ResourceData[]>(
    []
  )

  const resourceListCache = new Map<
    string,
    Domain.ResourcePlanner.DTO.ResourceData[]
  >()
  const resourceCache = new Map<
    number,
    Domain.ResourcePlanner.DTO.ResourceData
  >()
  function clearResourceCache(resourceId: number | null = null) {
    if (resourceId) {
      resourceCache.delete(resourceId)
    } else {
      resourceCache.clear()
    }
  }
  function clearCache() {
    resourceListCache.clear()
    resourceCache.clear()
  }

  // cache resources response by date
  const currentResources = ref(
    new Map<
      string, // date
      Domain.ResourcePlanner.DTO.ResourceData[]
    >()
  )
  watch(
    currentResources,
    () => {
      currentResources.value.forEach((resources, date) => {
        resourceListCache.set(date, resources)
      })
    },
    {
      deep: true,
    }
  )

  watch(
    searchString,
    debounce(async () => {
      const { data } = await resourceService.getResources({
        'date:between': dateRange.value.map((d) => d.format('YYYY-MM-DD')),
        search: searchString.value,
        perPage: -1,
      })
      resourcesSearchResult.value = data
    }, 300)
  )

  const loadingResources = ref(false)
  const openResourceCollection = ref<ResourceCollection | null>(null)
  const selectedResourceId = useSyncRouteParam<number | null>('resourceId', {
    defaultValue: null,
    type: 'number',
  })
  const getResourcesController = ref<AbortController | null>(null)

  watch(openResourceCollection, (collection) => {
    if (!collection) return
    const selectedCustomerResource = allResourceCollections.value.find(
      (g) => g.id === collection.id
    )
    if (!selectedCustomerResource) return

    if (selectedCustomerResource.resources.length === 1) {
      selectedResourceId.value = selectedCustomerResource?.resources[0]?.id
      return
    }
    if (
      selectedCustomerResource.resources.some(
        (r) => r.id === selectedResourceId.value
      )
    ) {
      return
    }
  })

  // getters
  const resourceCollectionsByDay = computed<ResourceCollectionByDay[]>(() => {
    const [firstDate, lastDate] = dateRange.value

    const collections: ResourceCollectionByDay[] = []

    for (
      const date = firstDate.clone();
      date <= lastDate;
      date.add(1, 'days')
    ) {
      const resources = (
        currentResources.value.get(date.format('YYYY-MM-DD')) || []
      ).filter((r) => {
        if (!searchString.value) {
          return true
        }

        return resourcesSearchResult.value.find((res) => res.id === r.id)
      })
      collections.push({
        date: date.format('YYYY-MM-DD'),
        resources: resources,
        items: resourcesToCollections(resources),
      })
    }

    return collections
  })

  const allResources = computed(() => {
    return resourceCollectionsByDay.value.flatMap((r) => r.resources)
  })
  const selectedResource = computed(() =>
    allResources.value.find((r) => r.id === selectedResourceId.value)
  )

  const resourcesAtSelectedDay = computed(
    () => currentResources.value.get(date.value) || []
  )

  const selectedEmployeeIds = ref<number[]>([])
  function calculateSelectedEmployeeIds() {
    selectedEmployeeIds.value = resourcesAtSelectedDay.value
      .flatMap((r) => r.planner_entries!)
      .map((r) => r.employee!.id)
  }

  const usedCarIds = computed(() =>
    resourcesAtSelectedDay.value.flatMap((r) => r.cars!).map((c) => c.id)
  )

  const isAllowedToEdit = computed(
    () =>
      useAuthStore().user?.hasPermission(
        ['superadmin'],
        ['resource_planner_write']
      ) || false
  )
  const disabled = computed(() => !isAllowedToEdit.value)
  const usedCustomers = computed(() =>
    resourcesAtSelectedDay.value
      .filter((r) => r.customer)
      .map((r) => r.customer)
  )

  const allResourceCollections = computed(() => {
    return resourceCollectionsByDay.value.flatMap((r) => r.items)
  })

  function resourcesToCollections(
    resources: Domain.ResourcePlanner.DTO.ResourceData[]
  ): ResourceCollection[] {
    const groups = resources.reduce((groups, resource) => {
      const mapKey = `${resource.worktype_id}-${resource.customer_id}`
      if (groups[mapKey]) {
        groups[mapKey].resources.push(resource)
        groups[mapKey].planner_entries.push(...resource.planner_entries!)
      } else {
        groups[mapKey] = {
          id: resource.id,
          name: resource.customer
            ? resource.customer.name
            : resource.worktype?.name_de,
          resources: [resource],
          planner_entries: [...resource.planner_entries!],
          customer: resource.customer,
        }
      }

      return groups
    }, {})

    return Object.keys(groups).map((key) => groups[key])
  }

  // actions
  function isEditable(
    resource?: Domain.ResourcePlanner.DTO.ResourceData | null
  ) {
    if (!isAllowedToEdit.value) {
      errorNotification(
        'Du hast nicht die nötige Berechtigung um den Ressourcenplaner zu bearbeiten'
      )
      return false
    }

    if (resource && resource.completed) {
      errorNotification(
        'Diese Gruppe wurde bereits abgeschlossen und kann nicht mehr bearbeitet werden'
      )
      return false
    }

    return true
  }

  function canAddItemToResource(_to, from, resource) {
    // tools and cars can not be added to the absance resources (free, sick, accident usw...)
    if (
      (!resource.customer ||
        resource.customer.always_show_on_resource_planner) &&
      ['car', 'tool', 'robot'].includes(from.el.dataset.draggableName)
    ) {
      return false
    }
    return Number(from.el.dataset.resourceId) !== resource.id
  }

  /**
   * Fetches the details of a single resource and updates the resource array.
   * Used to fetch the details of a resource when opening it or after editing it.
   */
  async function fetchResource(resourceId: number, force = false) {
    if (!force) {
      const resource = resourceCache.get(resourceId)
      if (resource) {
        updateResource(resource)
        return
      }
    }

    loadingResourceIds.value.push(resourceId)
    try {
      const resource = await resourceService.getResource(resourceId)
      resourceCache.set(resource.id, resource)

      updateResource(resource)
    } finally {
      loadingResourceIds.value = loadingResourceIds.value.filter(
        (r) => r !== resourceId
      )
    }
  }

  function updateResource(resource: Domain.ResourcePlanner.DTO.ResourceData) {
    const resources = currentResources.value.get(
      moment(resource.date).format('YYYY-MM-DD')
    )
    if (!resources) return

    const index = resources.findIndex((r) => r.id === resource.id)
    if (index === -1) return

    resources[index] = resource
  }

  /**
   * Fetches all resources for the selected date range and caches them.
   */
  async function fetchAllResources(force: boolean = false) {
    if (!date.value) return

    await fetchResources(date.value, force)

    const dates = createDatesArray(...dateRange.value)

    for (const d of dates.filter((d) => d !== date.value)) {
      await fetchResources(d, force)
    }

    // Remove resources that are not in the date range
    Array.from(currentResources.value.keys()).forEach((key) => {
      if (!dates.includes(key)) {
        currentResources.value.delete(key)
      }
    })

    calculateSelectedEmployeeIds()
  }

  /**
   * Fetch all resources for the current date and search string.
   */
  async function fetchResources(date: string, force = false) {
    const _date = moment(date)
    const formattedDate = _date.format('YYYY-MM-DD')

    if (
      resourceListCache.has(formattedDate) &&
      !force &&
      !invalidCacheDates.has(formattedDate)
    ) {
      if (!currentResources.value.has(formattedDate)) {
        currentResources.value.set(
          formattedDate,
          resourceListCache.get(formattedDate)!
        )
      }
      return
    }
    invalidCacheDates.delete(formattedDate)

    loadingResources.value = true

    try {
      loadingDates.value.push(formattedDate)
      const { data } = await resourceService.getResources({
        date: date,
        planner_mode: filters.value.editMode,
        search: searchString.value,
        perPage: -1,
      })
      // If resource is in cache, use the additional details from the cache.
      // We only use the additional details from the cache, because it is not guaranteed that the cache is up to date.
      // The additional details are fetched again, when the resource is opened. This way we can ensure that the details are up to date.
      data.forEach((resource, index) => {
        if (resourceCache.has(resource.id)) {
          data[index].orders = resourceCache.get(resource.id)!.orders
          data[index].rapportdetails = resourceCache.get(
            resource.id
          )!.rapportdetails
        }
      })
      // Remove the resource from the cache, because details should be fetched again when the resource is opened.
      data.forEach((resource) => {
        if (resourceCache.has(resource.id)) {
          resourceCache.delete(resource.id)
        }
      })

      currentResources.value.set(_date.format('YYYY-MM-DD'), data)

      if (
        selectedResource.value &&
        moment(selectedResource.value.date).isSame(date, 'date')
      ) {
        fetchResource(selectedResource.value.id, force)
      }
    } catch (e) {
    } finally {
      loadingDates.value = loadingDates.value.filter((d) => d !== formattedDate)
      loadingResources.value = false
    }
  }

  function getResources(date: Dateable) {
    const _date = moment(date)

    return currentResources.value.get(_date.format('YYYY-MM-DD')) || []
  }

  const invalidCacheDates = new Set<string>()
  function invalidateCache(date: Dateable, endDate: Dateable | null = null) {
    const dates = createDatesArray(date, endDate)
    for (const date of dates) {
      invalidCacheDates.add(moment(date).format('YYYY-MM-DD'))
    }
  }

  /**
   * Removes a resource from the cache.
   */
  function removeResource(resource: Domain.ResourcePlanner.DTO.ResourceData) {
    const resources = currentResources.value.get(
      moment(resource.date).format('YYYY-MM-DD')
    )
    if (!resources) return

    const index = resources.indexOf(
      resources.find((r) => r.id === resource.id)!
    )
    resources.splice(index, 1)
    currentResources.value.set(
      moment(resource.date).format('YYYY-MM-DD'),
      resources
    )
  }

  async function deleteResource(resource) {
    if (!isEditable(resource)) return

    let resourceExists = null
    try {
      // first check if the resource still exists
      // if not it throws an error and we skip the delete request
      resourceExists = await axios.$get(`resources/${resource.id}`)
    } catch (e) {
      handleError(e)
    }

    if (resourceExists) {
      await resourceService.deleteResource(resource.id)
    }

    removeResource(resource)

    if (
      openResourceCollection.value &&
      openResourceCollection.value.resources.length === 1
    ) {
      // use new instance of resourceCollection
      openResourceCollection.value =
        allResourceCollections.value.find(
          (c) => c.id === openResourceCollection.value?.id
        ) ?? null

      selectedResourceId.value =
        openResourceCollection.value?.resources[0].id ?? null
    } else {
      selectedResourceId.value = null
    }
  }

  async function confirmOverfill(options: Partial<SweetAlertOptions> = {}) {
    const { value } = await confirmAction({
      confirmButtonText: t('general.yes-add'),
      cancelButtonText: t('general.no'),
      showCancelButton: true,
      icon: 'warning',
      ...options,
    } as SweetAlertOptions)

    return value
  }

  return {
    // state
    date,
    additionalDays,
    dateRange,
    searchString,
    allResources,
    resourceCollectionsByDay,
    resourcesAtSelectedDay,
    loadingResources,
    loadingDates,
    loadingResourceIds,
    openResourceCollection,
    selectedResourceId,
    getResourcesController,
    filters,
    showRobots,
    showEmployees,

    // getters
    selectedResource,
    selectedEmployeeIds,
    usedCarIds,
    isAllowedToEdit,
    disabled,
    usedCustomers,
    allResourceCollections,

    // actions
    isEditable,
    canAddItemToResource,
    fetchResource,
    fetchResources,
    fetchAllResources,
    getResources,
    invalidateCache,
    deleteResource,
    removeResource,
    confirmOverfill,
    clearResourceCache,
    clearCache,
    calculateSelectedEmployeeIds,
  }
})
