import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/profile/change-password',
    name: 'profile.change-password',
    component: () => import('./pages/ProfilePasswordChange.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/profil',
    redirect: '/profile',
  },
  {
    path: '/profile',
    name: 'profile.edit',
    component: () => import('./pages/ProfileEdit.vue'),
    meta: { auth: true },
  },
]
