type PageRect = {
  top: number
  left: number
  width: number
  height: number
}

/**
 * Returns the position of an element relative to the viewport.
 *
 * This is useful for positioning tooltips and popovers. It takes into account
 * the scroll position of the viewport.
 *
 * @param element
 * @param type
 * @returns
 */
export function pageRect(
  element: HTMLElement,
  type: 'absolute' | 'fixed' = 'absolute'
): PageRect {
  const rect = element.getBoundingClientRect()

  return {
    top: rect.top + (type === 'fixed' ? 0 : window.scrollY),
    left: rect.left + (type === 'fixed' ? 0 : window.scrollX),
    width: rect.width,
    height: rect.height,
  }
}

/**
 * Ensures that the tooltip is within the viewport.
 * If it is not, it will be moved to the closest side.
 *
 * @param position {left, top}
 * @param tooltipRect {width, height}
 * @param margin {number} margin from the viewport edge
 * @param type 'absolute' | 'fixed' fixed or absolute positioning
 * @returns
 */
export function ensureWithinPage(
  position: { left: number; top: number },
  tooltipRect: PageRect,
  margin = 10,
  type: 'absolute' | 'fixed' = 'absolute'
) {
  const pos = { ...position }
  if (pos.left < 0) pos.left = margin

  if (pos.top < 0) pos.top = margin

  const scrollX = type === 'fixed' ? 0 : window.scrollX
  const scrollY = type === 'fixed' ? 0 : window.scrollY

  if (pos.left + tooltipRect.width > window.innerWidth + scrollX)
    pos.left = window.innerWidth + scrollX - tooltipRect.width - margin - 10

  if (pos.top + tooltipRect.height > window.innerHeight + scrollY)
    pos.top = window.innerHeight + scrollY - tooltipRect.height - margin - 10

  return pos
}

/**
 * Returns the position relative to an given element. Useful for positioning tooltips and popovers.
 */
export function positioningRelativeTo(
  position:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'bottom-left'
    | {
        top?: 'top' | 'bottom' | null
        left?: 'left' | 'right' | null
        bottom?: 'bottom' | 'top' | null
        right?: 'left' | 'right' | null
        centerHorizontal?: boolean
        centerVertical?: boolean
        space?: number
        marginTop?: number
      },
  hoverRect: PageRect,
  tooltipRect: PageRect,
  type: 'absolute' | 'fixed' = 'absolute'
) {
  let top, left
  if (typeof position === 'object') {
    if (position.top === 'top') {
      top = hoverRect.top
    }
    if (position.top === 'bottom') {
      top = hoverRect.top + hoverRect.height + (position.marginTop || 0)
    }
    if (position.centerVertical) {
      top = hoverRect.top + hoverRect.height - tooltipRect.height / 2
    }
    if (position.left === 'left') {
      left = hoverRect.left
    }
    if (position.left === 'right') {
      left = hoverRect.left + hoverRect.width
    }
    if (position.centerHorizontal) {
      left = hoverRect.left + hoverRect.width - tooltipRect.width / 2
    }
    if (position.bottom === 'top') {
      top = hoverRect.top - tooltipRect.height
    }
    if (position.bottom === 'bottom') {
      top = hoverRect.top - tooltipRect.height + hoverRect.height
    }
    if (position.right === 'left') {
      left = hoverRect.left - tooltipRect.width
    }
    if (position.right === 'right') {
      left = hoverRect.left - tooltipRect.width + hoverRect.width
    }
  } else {
    if (position === 'bottom-left') {
      top = hoverRect.top + hoverRect.height + 10
      left = hoverRect.left
    } else if (position === 'bottom') {
      top = hoverRect.top + hoverRect.height + 10
      left = hoverRect.left - tooltipRect.width / 2 + hoverRect.width / 2
    } else if (position === 'left') {
      top = hoverRect.top - tooltipRect.height / 2 + hoverRect.height / 2
      left = hoverRect.left - tooltipRect.width - 10
    } else if (position === 'right') {
      top = hoverRect.top - tooltipRect.height / 2 + hoverRect.height / 2
      left = hoverRect.left + hoverRect.width + 10
    } else {
      top = hoverRect.top - tooltipRect.height - 10
      left = hoverRect.left - tooltipRect.width / 2 + hoverRect.width / 2
    }
  }

  // If tooltip is off the top of the page, show it below the hover element
  if (top < 0) {
    top = hoverRect.top + hoverRect.height + 10
  }

  return ensureWithinPage(
    {
      top,
      left,
    },
    tooltipRect,
    typeof position === 'object' ? position.space || 10 : 10,
    type
  )
}

/**
 * Similar to positioningRelativeTo, but positions the tooltip on top of the hover element. Useful for popovers.
 */
export function positionOnTopOf(
  hoverRect: PageRect,
  tooltipRect: PageRect,
  align: 'left' | 'center' | 'right' = 'center',
  type: 'absolute' | 'fixed' = 'absolute'
) {
  let left
  const top = hoverRect.top
  if (align === 'left') {
    left = hoverRect.left
  } else if (align === 'center') {
    left = hoverRect.left - tooltipRect.width / 2 + hoverRect.width / 2
  } else if (align === 'right') {
    left = hoverRect.left + hoverRect.width - tooltipRect.width
  }

  return ensureWithinPage(
    {
      top,
      left,
    },
    tooltipRect,
    10,
    type
  )
}
