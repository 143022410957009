export type ErrorHandlers = Record<string, (message: string | null) => void>
import { t } from '@/plugins/i18n'
import { useDefaultStore } from '../store/default'
import { swal } from './sweetalert'
import posthog from 'posthog-js'

export function handleError(
  error: any,
  handlers: ErrorHandlers = {},
  fallback?: (() => void) | null
) {
  if (error.message === 'canceled' || error.name === 'CanceledError') {
    throw error
  }
  if (error.response?.data?.error && handlers[error.response.data.error]) {
    return handlers[error.response.data.error](error.response?.data?.message)
  }

  if (error.response?.data?.message && handlers[error.response.data.message]) {
    return handlers[error.response.data.message](error.response.data.message)
  }

  posthog.capture('handle_error', {
    status: error.response?.status,
    url: error.response?.config?.url,
    method: `${error.response?.config?.method || 'unknown'}`.toUpperCase(),
    response_data: error.response?.data,
    message: error.message,
    params: error.response?.config?.params,
    request_data: error.response?.config?.data,
  })

  if (error.response?.status === 422) {
    swal(t('general.invalid-data'), error.response.data.message || '', 'error')
    throw error
  }

  if (error.response?.data?.message) {
    swal(t('general.error'), error.response.data.message || '', 'error')
    throw error
  }

  if (fallback) {
    return fallback()
  }

  useDefaultStore().error(t('general.unknown-error'))
  throw error
}
