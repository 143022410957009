import axios from '@/plugins/axios'
// import Vue from 'vue'
import moment from 'moment'
import { defineStore } from 'pinia'
import { useLoadingStore } from '../../../store/loading'
import { useDefaultStore } from '../../../store/default'
import throttle from 'lodash/throttle'
import type { ContractType } from '@/features/resource-planner/api/ContractTypeService'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    timerecordSettings: {},
    settings: {},
    hourrecordSettings: {} as Record<string, any>,
    contractTypes: [] as ContractType[],
  }),
  getters: {
    isEditTime: (state) => {
      if (!state.hourrecordSettings) return false
      const startDate = moment(state.hourrecordSettings.hourrecordStartDate)
      const endDate = moment(state.hourrecordSettings.hourrecordEndDate)

      return moment().isBetween(startDate, endDate, 'day', '[]')
    },
    activeContractTypes: (state) =>
      state.contractTypes.filter((s) => s.is_active),
  },
  actions: {
    setTimerecordSettings(settings) {
      this.timerecordSettings = settings
    },
    setSettings(settings) {
      this.settings = settings
    },
    setHourreocrdSettings(settings) {
      this.hourrecordSettings = {
        ...settings,
      }
    },
    setContractTypes(value) {
      this.contractTypes = value
    },
    fetchTimerecordSettings(urlParams) {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ settings: true })
        axios
          .get(`settings/time${urlParams}`)
          .then((response) => {
            this.setTimerecordSettings(response.data)
            resolve(this.timerecordSettings)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Einstellungen.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ settings: false })
          })
      })
    },
    fetchSettings() {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ settings: true })
        axios
          .get('settings')
          .then((response) => {
            this.setSettings(response.data)
            resolve(this.settings)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Einstellungen')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ settings: false })
          })
      })
    },
    fetchHourrecordSettings: throttle(
      function () {
        return new Promise((resolve, reject) => {
          useLoadingStore().loading({ settings: true })
          axios
            .get('settings/hourrecords')
            .then((response) => {
              // @ts-expect-error this type definition is wrong because of the throttle
              this.setHourreocrdSettings(response.data)
              // @ts-expect-error this type definition is wrong because of the throttle
              resolve(this.hourrecordSettings)
            })
            .catch((error) => {
              useDefaultStore().error('Fehler beim Laden der Einstellungen')
              reject(error)
            })
            .finally(() => {
              useLoadingStore().loading({ settings: false })
            })
        })
      },
      1000,
      { trailing: false, leading: true }
    ),
    fetchContractTypes() {
      return new Promise((resolve, reject) => {
        if (this.contractTypes.length) {
          resolve(this.contractTypes)
        } else {
          axios
            .$get('contract-types')
            .then((data) => {
              this.setContractTypes(data)
              resolve(this.contractTypes)
            })
            .catch((error) => {
              useDefaultStore().error('Fehler beim Laden der Arbeitsverträge')
              reject(error)
            })
        }
      })
    },
  },
})
