export const outsideTargetSiblingMap = new WeakMap<Element, Element>()

/**
 * escape hatch for teleport element
 */
export function markSibling(outsideTarget: Element, sibling: Element): void {
  outsideTargetSiblingMap.set(outsideTarget, sibling)
}

export function unmarkSibling(outsideTarget: Element): void {
  outsideTargetSiblingMap.delete(outsideTarget)
}

function isOutside(targets: Element[], element: Element | null): boolean {
  const targetSet = new Set(targets)

  while (element) {
    const sibling = outsideTargetSiblingMap.get(element)
    if (
      targetSet.has(element) ||
      (sibling && targets.some((el) => el.contains(sibling)))
    ) {
      return false
    }
    element = element.parentElement
  }
  return true
}

function isSweetAlertDialog(element: HTMLElement): boolean {
  const swalContainer = element.closest('.swal2-container')
  return !!swalContainer
}

export const clickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      const outside = isOutside([el], event.target as Element)

      // If the sweet alert dialog is open, we don't want to close it when clicking outside
      const sweetAlertDialogClick = isSweetAlertDialog(
        event.target as HTMLElement
      )

      if (
        outside &&
        !sweetAlertDialogClick &&
        document.body.contains(event.target) // If the clicked element is not in the dom we don't know if it's outside or not
      ) {
        // and if it did, call method provided in attribute value
        binding.value()
      }
    }

    setTimeout(() => {
      document.addEventListener('click', el.clickOutsideEvent)
    }, 40)
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
}
