import { defineStore } from 'pinia'

export const useDefaultStore = defineStore('default', {
  state: () => ({
    isMobile: false,
    recordWeeks: [],
    hourRecords: [],
    saveState: {
      isSaving: false,
      saves: 0,
      saved: false,
    },
    alerts: [] as {
      text: string
      type: string
      key: number
    }[],
    preventFormSubmit: false,
    meilisearchApiKey: null as string | null,
    disableNavigationDrawerTouch: false,
  }),
  actions: {
    hourRecords(hourRecords) {
      this.hourRecords = hourRecords
    },
    isSaving(isSaving) {
      if (isSaving) {
        this.saveState.saves++
        this.saveState.isSaving = true
      } else {
        this.saveState.saves--
        if (this.saveState.saves === 0) {
          this.saveState.isSaving = false
          this.saveState.saved = true
          setTimeout(() => {
            this.saveState.saved = false
          }, 3000)
        }
      }
    },
    addAlert(alert) {
      this.alerts.push(alert)
    },
    removeAlert() {
      this.alerts.shift()
      this.alerts = [...this.alerts]
    },
    preventFormSubmit(value) {
      this.preventFormSubmit = value
    },

    alert(alert) {
      this.addAlert({
        ...alert,
        visible: true,
        key: Math.random(),
      })
      setTimeout(
        () => {
          this.removeAlert()
        },
        (alert.duration || 3) * 1000
      )
    },
    error(text) {
      this.alert({ text, type: 'error', duration: 4 })
    },
    success(text) {
      this.alert({ text, type: 'success' })
    },
    warning(text) {
      this.alert({ text, type: 'warning' })
    },
  },
})
