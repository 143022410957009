import { sheduleQueryUpdate } from '@/utils/router-helper'
import { isEqual } from 'lodash'
import { useRoute, useRouter } from 'vue-router'

export function useSyncRouteParam<
  T extends string | number | null | string[] | boolean,
>(
  name,
  {
    defaultValue = null,
    type = 'string',
    navigationMode = 'replace',
  }: {
    defaultValue?: T | null
    type?: 'string' | 'number' | 'array' | 'boolean'
    navigationMode?: 'replace' | 'push'
  } = {}
) {
  const route = useRoute()
  const router = useRouter()

  function parseValue() {
    if (type === 'boolean' || typeof defaultValue === 'boolean') {
      return (route.query[name] === 'true') as T
    }

    if (type === 'number' || typeof defaultValue === 'number') {
      return route.query[name]
        ? (Number(route.query[name]) as T)
        : (route.query[name] as T)
    }
    if (
      type === 'array' ||
      (typeof defaultValue === 'object' && Array.isArray(defaultValue))
    )
      return route.query[name] as T

    return route.query[name] as T
  }

  const state = ref<T | null>(parseValue() || defaultValue) as Ref<T | null>

  onMounted(() => {
    if (
      defaultValue !== null &&
      (route.query[name] === null || route.query[name] === undefined)
    ) {
      // if the query param is not set, set it to the default value
      sheduleQueryUpdate({ [name]: defaultValue }, router)
    }
  })

  watch(
    () => route.query,
    () => {
      const newValue = parseValue() === undefined ? defaultValue : parseValue()
      if (newValue !== state.value) {
        state.value = newValue
      }
    },
    {
      deep: true,
    }
  )

  return computed<T>({
    get() {
      return state.value as T
    },
    set(value) {
      state.value = value as T
      if (navigationMode === 'push') {
        router.push({ query: { ...route.query, [name]: value } })
      } else {
        sheduleQueryUpdate({ [name]: value }, router)
      }
    },
  }) as Ref<T>
}

type RouteParamType =
  | string
  | number
  | null
  | boolean
  | (string | number | null)[]

export function useSyncRouteParams<T extends Record<string, RouteParamType>>(
  defaultValue: T,
  types: Partial<
    Record<
      keyof T,
      'string' | 'number' | 'array' | 'boolean' | 'nullable-boolean'
    >
  > = {} as any
) {
  const route = useRoute()
  const router = useRouter()

  const state = ref<T>(parseState())

  function parseState() {
    const state = {
      ...(defaultValue || {}),
      ...route.query,
    }

    const result = Object.keys(state).reduce((acc, key) => {
      if (!(key in defaultValue)) {
        return acc
      }

      const value = state[key]
      const type = types[key] ? types[key] : typeof defaultValue[key]

      if (type === 'boolean') {
        if (defaultValue[key] === null && !state[key]) {
          acc[key] = null
        } else {
          acc[key] = state[key] === true || state[key] === 'true'
        }
        return acc
      }
      if (type === 'nullable-boolean') {
        if (state[key] === null || state[key] === undefined) {
          acc[key] = null
        } else {
          acc[key] = state[key] === true || state[key] === 'true'
        }
        return acc
      }

      if (!value) {
        acc[key] = null
        return acc
      }

      if (
        Array.isArray(defaultValue[key]) &&
        ((defaultValue[key]?.[0] &&
          typeof defaultValue[key]?.[0] === 'number') ||
          types[key] === 'number')
      ) {
        if (state[key] === null || state[key] === undefined) {
          acc[key] = []
        } else if (Array.isArray(state[key])) {
          acc[key] = (state[key] as string[]).map(Number)
        } else {
          acc[key] = [state[key]].map(Number)
        }

        return acc
      }

      if (type === 'number') {
        acc[key] = Number(state[key])
        return acc
      }

      if (Array.isArray(defaultValue[key])) {
        if (state[key] === null || state[key] === undefined) {
          acc[key] = []
        } else if (Array.isArray(state[key])) {
          acc[key] = state[key]
        } else {
          acc[key] = [state[key]] as string[]
        }

        return acc
      }

      acc[key] = state[key]
      return acc
    }, {})

    return result as T
  }

  watch(
    () => route.query,
    () => {
      const newState = parseState()

      if (!isEqual(newState, state.value)) {
        state.value = newState
      }
    },
    {
      deep: true,
    }
  )

  watch(
    state,
    (value) => {
      sheduleQueryUpdate(
        {
          ...value,
        },
        router
      )
    },
    { deep: true }
  )

  return state
}
